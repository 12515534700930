import React from 'react';
import { useLoading } from '../providers/LoadingContext';

import '../assets/scss/global-loader.scss';

const GlobalLoader = () => {
	const { isLoading } = useLoading();

	if (!isLoading) return null;

	return (
		<div className="loader-overlay">
			<div className="loader-spinner">
				<div className="spinner"></div>
			</div>
		</div>
	);
};

export default GlobalLoader;
import axiosInstance from "../utils/axios";

const apiPath = '/api/order';

const OrderApi = {
	get: function (orderNumber, email) {
		return axiosInstance.get(`${apiPath}/find?orderNumber=${orderNumber}&email=${email}`);
	},
	getById: function (orderId) {
		return axiosInstance.get(`${apiPath}/findById?orderId=${orderId}`);
	}
}

export default OrderApi;

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useLoading } from '../providers/LoadingContext';
import orderApi from '../api/order';

import '../assets/scss/search-order-page.scss';

const SearchOrderPage = () => {
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();

  const [orderEmail, setOrderEmail] = useState('');
  const [orderNumber, setOrderNumber] = useState('');

  const handleChangeOrderEmail = (event) => {
    setOrderEmail(event.target.value);
  };

  const handleChangeOrderNumber = (event) => {
    setOrderNumber(event.target.value);
  };

  useEffect(() => {
    window.parent.postMessage({
      'frameHeight': document.body.scrollHeight
    }, "*");
  }, []);

  const handleFindOrder = async () => {
    setIsLoading(true);

    await orderApi.get(orderNumber, orderEmail)
      .then(response => {
        if (response?.data?.id) {
          enqueueSnackbar('Your order was successfully found', { variant: 'success' });
          window.parent.postMessage({
            'action': 'receiveOrderId',
            'data': response?.data?.id
          }, "*");
          // navigate(`/pages/advanced-order-status/${response?.data?.id}`);
        }
      })
      .catch(error => {
        enqueueSnackbar(error?.message || 'Something went wrong', { variant: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sendRedirectMessage = (url, target) => {
    window.parent.postMessage({
      'action': 'redirect',
      'target': target,
      'data': url
    }, "*");
  };

  return (
    <div className="search-order-page">
      <div className="top-block">
        <div className="order-lookup-block">
          <span className="title">Order Lookup</span>
          <div className="input">
            <span className="label">Email</span>
            <input className="custom-input" type="text" onChange={handleChangeOrderEmail} />
          </div>
          <div className="input">
            <span className="label">Order number</span>
            <input className="custom-input" type="text" onChange={handleChangeOrderNumber} />
          </div>
          <button className="custom-btn" onClick={handleFindOrder}>Find Order</button>
        </div>
      </div>
    </div>
  );
}

export default SearchOrderPage;

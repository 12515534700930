import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { useLoading } from '../providers/LoadingContext';
import orderApi from '../api/order';

import '../assets/scss/order-page.scss';

const OrderPage = () => {
  const { orderId } = useParams();
  const { setIsLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [currentOrder, setCurrentOrder] = useState(null);
  const [statusesList, setStatusesList] = useState([]);
  const [trackingsList, setTrackingsList] = useState([]);
  const [productsByCategories, setProductsByCategories] = useState({});
  const [backorderedMetafield, setBackorderedMetafield] = useState(null);

  const categoryTitles = {
    backordered: 'BACKORDERED',
    cancelledRefunded: 'CANCELLED/REFUNDED',
    processing: 'PROCESSING',
    returnInitiated: 'RETURN INITIATED',
    shipped: 'SHIPPED'
  };

  const navigateBack = () => {
    window.parent.postMessage({
      'action': 'redirectBack'
    }, "*");
    // navigate('/');
  };

  const parsePrice = (price) => {
    if (price) {
      return parseFloat(price).toFixed(2);
    }

    return null;
  };

  const formatBackorderedDate = (date) => {
    const dateObj = new Date(date);

    return format(dateObj, 'MMMM do, yyyy');
  };

  const isPriceNotZero = (price) => {
    return parseFloat(price) !== 0;
  };

  const sendRedirectMessage = (url, target) => {
    window.parent.postMessage({
      'action': 'redirect',
      'target': target,
      'data': url
    }, "*");
  };

  useEffect(() => {
    window.parent.postMessage({
      'frameHeight': 1000
    }, "*");
  }, []);

  useEffect(() => {
    if (currentOrder) {
      window.parent.postMessage({
        'frameHeight': document.body.scrollHeight
      }, "*");
    }
  }, [currentOrder]);

  useEffect(() => {
    if (orderId) {
      setIsLoading(true);

      orderApi.getById(orderId)
        .then(response => {
          const order = response?.data?.order;
          const statuses = response?.data?.statuses;
          const trackings = response?.data?.trackings;
          const products = response?.data?.productsByCategories;
          const metafield = response?.data?.backorderedMetafield?.node;

          if (order) {
            setCurrentOrder(order);
          }

          if (statuses) {
            setStatusesList(statuses);
          }

          if (trackings) {
            setTrackingsList(trackings);
          }

          if (products) {
            setProductsByCategories(products);
          }

          if (metafield) {
            setBackorderedMetafield(metafield);
          }
        })
        .catch(error => {
          enqueueSnackbar(error?.message || 'Something went wrong', { variant: 'error' });
          navigateBack();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [orderId]);

  return (
    <div className="order-page">
      {currentOrder && (
        <>
          <div className="left-block">
            <div className="top-block">
              <span className="order-number">Order {currentOrder?.name}</span>
              <span className="thank-you-text">Thank you for your order! Please come back to this page for more status updates.</span>
            </div>
            <div className="statuses-block">
              {statusesList.map((status, index) => (
                <div className="status-line" key={index}>
                  <div className="left">
                    <img src={`${process.env.PUBLIC_URL}/images/checked-mark.svg`} />
                    <div className="vertical-line"></div>
                  </div>
                  <div className="right">
                    <span className="status-title">{status.title}</span>
                    <span className="status-description">{status.description}</span>
                    <span className="status-date">{status.created_at}</span>
                  </div>
                </div>
              ))}
            </div>
            {Boolean(trackingsList.length) && (
              <div className="tracking-block">
                {trackingsList.map((tracking, key) => (
                  <div className="track" key={key}>
                    <span className="title">{tracking.company} Tracking Number</span>
                    <div className="link">
                      <a href="#" onClick={() => { sendRedirectMessage(tracking.url, '_blank') }}>{tracking.number}</a>
                      <img src={`${process.env.PUBLIC_URL}/images/arrow-forward.svg`} />
                    </div>
                  </div>
                ))}
              </div>
            )}
            <button className="custom-btn" onClick={navigateBack}>Search Orders</button>
            <div className="bottom-block">
              <img src={`${process.env.PUBLIC_URL}/images/question-mark.svg`} />
              <span className="text"><b>Need help?</b> <a href="#" onClick={() => { sendRedirectMessage('/pages/contact', null) }}>Contact us</a> or visit or our <a href="#" onClick={() => { sendRedirectMessage('/pages/shipping-returns', null) }}>shipping and return policies</a> for more information.</span>
            </div>
          </div>
          <div className="right-block">
            <div className="top-block">
              <span className="title">Order Status Details</span>
            </div>
            <div className="products-block">
              {Object.keys(productsByCategories).map(categoryName => (
                Boolean(productsByCategories[categoryName].length) && (
                  <div className="category-block" key={categoryName}>
                    <span className="category-name">
                      {categoryTitles[categoryName] || ''}
                      {categoryName === 'returnInitiated' && (
                        <span className="category-description">{productsByCategories[categoryName].map(product => product.returnNumber).join(', ')}</span>
                      )}
                      {categoryName === 'backordered' && (
                        <span className="category-description">Expected to ship by {formatBackorderedDate(backorderedMetafield?.value)}</span>
                      )}
                    </span>
                    <div className="products-list">
                      {productsByCategories[categoryName].map((product, index) => (
                        <div className="product" key={index}>
                          <div className="image">
                            <img src={product?.image?.url} />
                            <span className="qty-circle">{product?.showQty}</span>
                          </div>
                          <div className="full-name">
                            <span className="title">{product?.title || product?.productTitle}</span>
                            <span className="variation-title">{product?.variantTitle}</span>
                            <div className="price mobile-only">
                              <span className="value">${parsePrice(product?.originalUnitPriceSet?.presentmentMoney?.amount) || parsePrice(product?.financialSummaries[0]?.originalUnitPriceSet?.presentmentMoney?.amount)}</span>
                            </div>
                          </div>
                          <div className="price web-only">
                            <span className="value">${parsePrice(product?.originalUnitPriceSet?.presentmentMoney?.amount) || parsePrice(product?.financialSummaries[0]?.originalUnitPriceSet?.presentmentMoney?.amount)}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )
              ))}
            </div>
            <div className="hr-line"></div>
            <div className="subtotal-block">
              <div className="line">
                <span className="title">Subtotal</span>
                <span className="value">${currentOrder?.subtotalPriceSet?.presentmentMoney?.amount}</span>
              </div>
              <div className="line">
                <span className="title">Tax</span>
                <span className="value">${parsePrice(currentOrder?.totalTaxSet?.presentmentMoney?.amount)}</span>
              </div>
              {isPriceNotZero(currentOrder?.totalRefundedSet?.presentmentMoney?.amount) && (
                <div className="line">
                  <span className="title">Refund Amount</span>
                  <span className="value">- ${currentOrder?.totalRefundedSet?.presentmentMoney?.amount}</span>
                </div>
              )}
            </div>
            <div className="hr-line"></div>
            <div className="total-block">
              <div className="line">
                <span className="title">Total</span>
                <span className="value">${parseFloat(currentOrder?.totalPriceSet?.presentmentMoney?.amount) - parseFloat(currentOrder?.totalRefundedSet?.presentmentMoney?.amount)}</span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default OrderPage;

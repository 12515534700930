// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Inter-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: Inter;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
body {
  margin: 0;
  font-family: Inter;
}

.custom-btn {
  border: 0px;
  max-width: 100%;
  height: 40px;
  background: #E9B422;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
}
.custom-btn:hover {
  background: #FBCD4F;
}

a {
  color: #005B75;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-decoration-line: underline;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/global.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,+DAAA;AACF;AAEA;EACE,SAAA;EACA,kBAAA;AAAF;;AAGA;EACE,WAAA;EACA,eAAA;EACA,YAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AAAF;AAEE;EACE,mBAAA;AAAJ;;AAIA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,+BAAA;AADF","sourcesContent":["@font-face {\n  font-family: Inter;\n  src: url('../fonts/Inter-Regular.ttf') format('truetype');\n}\n\nbody {\n  margin: 0;\n  font-family: Inter;\n}\n\n.custom-btn {\n  border: 0px;\n  max-width: 100%;\n  height: 40px;\n  background: #E9B422;\n  color: #000;\n  text-align: center;\n  font-family: Inter;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 24px;\n  cursor: pointer;\n\n  &:hover {\n    background: #FBCD4F;\n  }\n}\n\na {\n  color: #005B75;\n  font-family: Inter;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px;\n  text-decoration-line: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

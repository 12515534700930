import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { LoadingProvider } from './providers/LoadingContext';

import GlobalLoader from './components/GlobalLoader';

import SearchOrderPage from './pages/SearchOrderPage';
import OrderPage from './pages/OrderPage';

import './assets/scss/global.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <LoadingProvider>
      <SnackbarProvider maxSnack={3}>
        <Router>
          <GlobalLoader />
          <Routes>
            <Route path="/" element={<SearchOrderPage />} />
            <Route path="/pages/advanced-order-status/:orderId" element={<OrderPage />} />
          </Routes>
        </Router>
      </SnackbarProvider>
    </LoadingProvider>
  </React.StrictMode>
);